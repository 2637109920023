import React from "react";
import slide1 from "../../assets/extrainfo/slide1.png";

const InfoCard = () => {
  return (
    <div className="w-[24.73vw] shadow-gray-500 shadow-lg rounded-lg flex items-center flex-col justify-between aspect-[475/555]">
      <img src={slide1} alt="" />
      <div className="mx-[4vw] text-justify font-FiraSans text-[#45177D] leading-tight">
        <h3 className="mb-[2vh]">Major Benefits:</h3>
        <p className="font-[200]">
          Real time prediction of travel demand and travel time along a bus
          route at different time periods for each day.
        </p>
      </div>
      <div className="flex space-x-[0.3vw] mb-[2vh]">
        <div
          className={
            "h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square "
          }
        ></div>
        <div
          className={
            "h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square "
          }
        ></div>
        <div
          className={
            "h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square "
          }
        ></div>
        <div
          className={
            "h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square "
          }
        ></div>
        <div
          className={
            "h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square "
          }
        ></div>
        <div
          className={
            "h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square "
          }
        ></div>
      </div>
    </div>
  );
};

export default InfoCard;
