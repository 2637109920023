import React from "react";

const VideoCard = () => {
  return (
    <div className="w-[40vw] rounded-xl bg-white aspect-[806/453]">
      <iframe
        className="w-[40vw] aspect-[806/453] rounded"
        src="https://www.youtube.com/embed/SySKzWjvFqc?autoplay=1&mute=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default VideoCard;
