import React from "react";
import card1 from "../../assets/Network/card1.png";
import circle1 from "../../assets/Network/circle1.png";

const ImnagTextCard = () => {
  return (
    <div className="flex">
      <div className="p-[5vw] relative">
        <img
          className="absolute h-[20vh] right-[3vw] top-[4vw]"
          src={circle1}
          alt=""
        />
        <img className="h-[35vh]" src={card1} alt="" />
      </div>
      <div className="p-[5vw] w-1/2 h-[50vh] flex items-center justify-center place-items-center content-center">
        <ul className="list-disc text-[1.5vw] space-y-[2vh] text-[#45177D]">
          <li>
            Identify bus stops, intersections and intermediate points on open
            source map/satellite image.
          </li>
          <li>
            Seamless integration of passenger demand at each bus stop at
            different times of the day.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ImnagTextCard;
