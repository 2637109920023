import React from "react";
import InfoCard from "./infocard";
import leftarrow from "../../assets/leftarrow.svg";
import rightarrow from "../../assets/rightarrow.svg";

const InfoCarousel = () => {
  return (
    <div className="w-[33.9vw] ml-[8vw] px-[4.5vw] relative aspect-[651/583]">
      <img
        className="absolute top-[25vh] w-[4vw] left-0"
        src={leftarrow}
        alt=""
      />
      <img
        className="absolute right-0 z-20 top-[25vh] w-[4vw]"
        src={rightarrow}
        alt=""
      />
      <InfoCard></InfoCard>
    </div>
  );
};

export default InfoCarousel;
