import React, { useState } from "react";
import carousel1 from "../assets/carousel1.svg";
import carousel2 from "../assets/carousel2.svg";
import carousel3 from "../assets/carousel3.svg";
import leftarrow from "../assets/leftarrow.svg";
import rightarrow from "../assets/rightarrow.svg";
import FeatureBox from "../components/featureBox";
import ExtraInfo from "../components/extrainfo/extrainfo";
import { useNavigate } from "react-router-dom";
import VideoCard from "../components/video/VideoCard";
import feature1 from "../assets/features/feature1.png";
import feature2 from "../assets/features/feature2.png";
import feature3 from "../assets/features/feature3.png";
import feature4 from "../assets/features/feature4.png";
import feature5 from "../assets/features/feature5.png";
import feature6 from "../assets/features/feature6.png";
import feature7 from "../assets/features/feature7.png";

const Home = () => {
  const navigate = useNavigate();

  const features = [
    {
      image: feature1,
      text: "Bus Service Planning and Operation Software",
    },
    {
      image: feature2,
      text: "Network creation and visualization",
    },
    {
      image: feature3,
      text: "Single depot multiple route frequency setting and timetable development",
    },
    {
      image: feature4,
      text: "Single and Multiple route sequential and simultaneous scheduling",
    },
    {
      image: feature5,
      text: "Crew rostering",
    },
    {
      image: feature6,
      text: "Dispatch, Control and Surveillance System",
    },
    {
      image: feature7,
      text: "Onboard Driver console",
    },
  ];

  const paragraphs = [
    "First software designed to build solutions synonymous to current manual practices of operation and management, with the advantages of artificial intelligence, information technology, and data visualization.",
    "Designed to control over speeding and bus bunching, improve fuel and fleet utilization, vacant seat reduction.",
    "Improved service reliability, comfort level, waiting time and eventually commuter satisfaction resulting in increased patronage in public transport.",
  ];

  const carouselSvgs = [carousel1, carousel2, carousel3];

  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleLeftArrowClick = () => {
    let newIndex = 0;
    if (carouselIndex === 0) {
      newIndex = 2;
    } else {
      newIndex = carouselIndex - 1;
    }

    setCarouselIndex(newIndex);
  };

  const handleRightArrowClick = () => {
    let newIndex = (carouselIndex + 1) % 3;
    setCarouselIndex(newIndex);
  };

  return (
    <div className="w-auto">
      <div className="w-auto flex h-[90vh]">
        <div className="h-full w-[41%] bg-[#E32F6B14] relative">
          <div className="rounded-xl absolute -right-[5vw] top-[30vh] shadow-xl">
            <div className="w-full h-[3vh] rounded-t-lg bg-[#8C025D]"></div>
            <VideoCard></VideoCard>
          </div>
        </div>
        <div className="flex-grow ml-[5vw] flex flex-col h-full items-center justify-center">
          <h2 className="text-left font-FireSans leading-tight text-[#45177D] font-[700] text-[3.7vw]">
            Integrated Planning and
            <br />
            Operation Software for Bus
            <br />
            Transit Operators
          </h2>

          <h3 className="text-left w-full pl-[5.7vw] mt-[8vh] text-[2.2vw] font-[600] font-FiraSans text-[#8C025D]">
            PUBBS Transit v2.0
          </h3>

          <p className="text-left leading-tight w-full pl-[5.7vw] text-[1.1vw] font-extralight font-FiraSans text-[#45177D]">
            An initiative that aims at improving, enhancing, and easing out the
            bus
            <br />
            operation services within cities by making it more dynamic,
            optimized
            <br />
            and accurate for the operator and the users alike.
          </p>

          <div className="text-left font-FireSans mt-[8vh] flex space-x-[3vw] pl-[5.7vw] w-full">
            <button className="text-[#8C025D] font-[600] border-[#8C025D] py-[1vw] px-[2vw]  rounded-[5vw] border-[0.2vw] text-[1vw]">
              WATCH MORE VIDEOS
            </button>

            <button className="text-white bg-gradient-to-r from-[#8C025D] to-[#45177D] font-[600] border-[#8C025D] py-[1vw] px-[3vw] rounded-[5vw] border-[0.2vw] text-[1vw]">
              REQUEST A DEMO
            </button>
          </div>
        </div>
      </div>
      <div className="pt-[5.2vh] px-[4vw]">
        <div className="relative h-[138vh]">
          <img
            onClick={handleLeftArrowClick}
            className="absolute top-[34vh] w-[5vw]"
            src={leftarrow}
            alt=""
          />
          <img
            onClick={handleRightArrowClick}
            className="absolute right-0 z-20 top-[34vh] w-[5vw]"
            src={rightarrow}
            alt=""
          />
          <div className="absolute top-[22vh] left-[10vw]">
            <h3 className="text-left font-FireSans leading-tight text-[#5B2980] font-[700] text-[3.2vw]">
              PUBBS Transit v2.O
            </h3>
            <p className="text-[#45177D] w-[37vw] font-[400] text-justify mt-[2vh] leading-tight text-[1.6vw]">
              {paragraphs[carouselIndex]}
            </p>
          </div>
          <div className="absolute top-[70vh] left-[28vw] flex space-x-[3vh]">
            <div
              className={
                "h-[1.1vw] border-[#45177D] border-[0.15vw] rounded-full aspect-square " +
                (carouselIndex === 0 ? "bg-[#45177D]" : "")
              }
            ></div>
            <div
              className={
                "h-[1.1vw] border-[#45177D] border-[0.15vw] rounded-full aspect-square " +
                (carouselIndex === 1 ? "bg-[#45177D]" : "")
              }
            ></div>
            <div
              className={
                "h-[1.1vw] border-[#45177D] border-[0.15vw] rounded-full aspect-square " +
                (carouselIndex === 2 ? "bg-[#45177D]" : "")
              }
            ></div>
          </div>
          <img
            src={carouselSvgs[carouselIndex]}
            className="z-10 absolute right-0 h-[86vh]"
          />
          <svg
            className="absolute right-0 h-[86vh]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 940 800"
            fill="none"
          >
            <path
              d="M0 800C228 650 299.5 323.5 299.5 0H940V800H939.48H0Z"
              fill="#D4C6E5"
            />
          </svg>
          <div className="absolute top-[82vh] font-FiraSans left-[4vw] h-[47vh] rounded-l-none rounded-t-3xl rounded-3xl w-[38vw] bg-[#894472]">
            <h1 className="m-[3vw] text-white text-[1.5vw] ">
              Bus Service Planning and Operation Software with focus on:
            </h1>
            <ul className="list-disc mx-[5vw] text-white text-[1.2vw]">
              <li>Bus operators’ profit and operation efficiency</li>
              <li>Comfort and convenience of public bus transport users</li>
              <li>Customizable as per regional and local context and goals</li>
            </ul>
          </div>
          <div className="absolute mb-[5vh] font-FiraSans top-[82vh] right-[5vw] h-[47vh] rounded-l-3xl rounded-b-none  rounded-3xl w-[38vw] bg-[#4B3369] z-10">
            <h1 className="m-[3vw] text-white text-[1.5vw] ">
              System built on extensive datasets for accurate predictions for:
            </h1>
            <ul className="list-disc mx-[5vw] text-white text-[1.2vw]">
              <li>Bus and crew schedules</li>
              <li>Fleet size and Service frequency.</li>
              <li>
                Long and short term travel demand assessment in the most
                convenient and automated manner.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full px-[3vw] h-[120vh] bg-[#68507a2b] grid grid-cols-4 place-items-center content-evenly">
        <div className="w-[18vw] rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white">
          <img src={features[0].image} alt="" />
          <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[1vw] flex-grow justify-center">
            {features[0].text}
          </h1>
        </div>

        <div className="w-[18vw] rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white">
          <img src={features[1].image} alt="" />
          <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[1vw] flex-grow justify-center">
            {features[1].text}
          </h1>
        </div>

        <div className="w-[18vw] rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white">
          <img src={features[2].image} alt="" />
          <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[1vw] flex-grow justify-center">
            {features[2].text}
          </h1>
        </div>

        <div className="w-[18vw] rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white">
          <img src={features[3].image} alt="" />
          <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[1vw] flex-grow justify-center">
            {features[3].text}
          </h1>
        </div>

        <div className="w-[18vw] rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white">
          <img src={features[4].image} alt="" />
          <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[1vw] flex-grow justify-center">
            {features[4].text}
          </h1>
        </div>

        <div className="w-[18vw] rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white">
          <img src={features[5].image} alt="" />
          <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[1vw] flex-grow justify-center">
            {features[5].text}
          </h1>
        </div>

        <div className="w-[18vw] rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white">
          <img src={features[6].image} alt="" />
          <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[1vw] flex-grow justify-center">
            {features[6].text}
          </h1>
        </div>
      </div>
      <ExtraInfo></ExtraInfo>
    </div>
  );
};

export default Home;
