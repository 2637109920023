import React from "react";
import logo from "../../assets/pubbs-logo.png";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="w-auto space-x-[1.5vw] text-[2vh] px-[2vw] h-[10.5vh] items-center text-white bg-gradient-to-r flex from-[#8C025D] to-[#45177D]">
      <img
        onClick={() => {
          navigate("/");
        }}
        className="absolute w-[6.1vw] top-[1.8vh] left-[2.5vw]"
        src={logo}
        alt=""
      />
      <div className="flex-grow"></div>
      <button>Software</button>
      <button
        onClick={() => {
          navigate("/network");
        }}
      >
        Network
      </button>
      <button>Frequency Setting</button>
      <button>Scheduling</button>
      <button>Crew Rostering</button>
      <button>Control System</button>
      <button>Driver Console</button>
      <button>Resources</button>
      <button>Electric Bus</button>
      <button>Contact</button>
    </div>
  );
};

export default Header;
