import React from "react";
import citylabslogo from "../../assets/citylabs.png";
import instagram from "../../assets/instagram.svg";
import linkedin from "../../assets/linkedin.svg";

const Footer = () => {
  return (
    <div className="w-auto aspect-[1920/470] flex bg-gradient-to-r from-[#8A025D] to-[#45177D]">
      {/*  */}
      <div className="h-full w-[25%] pt-[3vh] pl-[5vw] flex flex-col">
        <img className="aspect-square w-[7vw]" src={citylabslogo} alt="" />
        <h3 className="leading-[1] mt-[1vh] text-white">
          City Future Lab
          <br />
          IIT Kharagpur. 2019
        </h3>
        <div className="font-bold text-[1.3vw] font-FiraSans text-white mt-[15vh]">
          <h2>Follow us on</h2>
          <div className="flex space-x-[2vw]">
            <img className="h-[5vh]" src={linkedin} alt="" />
            <img className="h-[5vh]" src={instagram} alt="" />
          </div>
        </div>
      </div>
      {/*  */}
      <div className="h-full text-white flex-grow font-FiraSans">
        <h1 className="mt-[3vh] pb-[1vh] text-[2vw] border-b mr-[5vw] border-white">
          Get in touch with us
        </h1>
        <div className="grid grid-cols-3 mt-[10vh] leading-tight">
          <div>
            <h1 className="underline text-[1.3vw]">Address</h1>
            <p className="mt-[1vh]">
              Department of Architecture and Regional Planning,
              <br />
              Indian Institute of Technology, Kharagpur,
              <br />
              West Bengal, India - 721302
            </p>
          </div>
          <div className="ml-[5vw]">
            <h1 className=" text-[1.3vw]">Phone Number</h1>
            <p className="mt-[1vh] underline">
              +91-3222283202
              <br />
              +91-9433944708
            </p>
          </div>
          <div className="mr-[5vw]">
            <h1 className="underline text-[1.3vw]">Email ID</h1>
            <p className="mt-[1vh] ">ar.research2019debapratim@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
