import "./App.css";
import Home from "./pages/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { Route, Routes } from "react-router-dom";
import Network from "./pages/Network";

function App() {
  return (
    <>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/network" element={<Network></Network>}></Route>
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
