import React from "react";
import ImnagTextCard from "../components/network/ImnagTextCard";
import card2 from "../assets/Network/card2.png";
import circle2 from "../assets/Network/circle2.png";
import card3 from "../assets/Network/card3.png";
import circle3 from "../assets/Network/circle3.png";
import card4 from "../assets/Network/card4.png";
import circle4 from "../assets/Network/circle4.png";
import VideoCard from "../components/video/VideoCard";

const Network = () => {
  return (
    <div className="w-auto font-FiraSans leading-tight">
      <div className="w-auto h-[80vh] flex">
        <div className="h-[60vh] relative w-[80%] bg-[#E32F6B14]">
          <div className="absolute -right-[7vw] -bottom-[15vh] shadow-lg rounded-xl">
            <VideoCard></VideoCard>
          </div>
          <h2 className="text-left pl-[5vw] pt-[10vh] font-FireSans leading-tight text-[#45177D] font-[700] text-[3.7vw]">
            Network Creation and
            <br />
            Visualization
          </h2>
        </div>
      </div>
      <div>
        <ImnagTextCard></ImnagTextCard>
        <div className="flex">
          <div className="p-[5vw] w-1/2 h-[50vh] flex items-center justify-center place-items-center content-center">
            <ul className="list-disc text-[1.5vw] space-y-[2vh] text-[#45177D]">
              <li>
                Build your route network connecting stops in UP and Down
                direction.
              </li>
              <li>
                Advance ridership prediction models integrating LAND USE & ETM
                data.
              </li>
            </ul>
          </div>
          <div className="p-[5vw] relative">
            <img
              className="absolute h-[20vh] right-[3vw] top-[4vw]"
              src={circle2}
              alt=""
            />
            <img className="h-[35vh]" src={card2} alt="" />
          </div>
        </div>

        <div className="flex">
          <div className="p-[5vw] relative">
            <img
              className="absolute h-[20vh] right-[3vw] top-[4vw]"
              src={circle3}
              alt=""
            />
            <img className="h-[35vh]" src={card3} alt="" />
          </div>
          <div className="p-[5vw] w-1/2 h-[50vh] flex items-center justify-center place-items-center content-center">
            <ul className="list-disc text-[1.5vw] space-y-[2vh] text-[#45177D]">
              <li>
                Bus travel time prediction at different times of the day using
                artificial intelligence.
              </li>
            </ul>
          </div>
        </div>

        <div className="flex">
          <div className="p-[5vw] w-1/2 h-[50vh] flex items-center justify-center place-items-center content-center">
            <ul className="list-disc text-[1.5vw] space-y-[2vh] text-[#45177D]">
              <li>Visualize route network served by each depot.</li>
              <li>Bus stop details</li>
            </ul>
          </div>
          <div className="p-[5vw] relative">
            <img
              className="absolute h-[20vh] right-[3vw] top-[4vw]"
              src={circle4}
              alt=""
            />
            <img className="h-[35vh]" src={card4} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Network;
