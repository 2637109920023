import React from "react";
import InfoCarousel from "./infocarousel";

const ExtraInfo = () => {
  return (
    <div className="w-full py-[13vh] flex">
      <InfoCarousel></InfoCarousel>
      <div className="ml-[2vw] text-[#5B2980]">
        <h2 className="text-left font-FireSans leading-tight font-[700] text-[3vw]">
          Why PUBBS Transit v2.0?
        </h2>
        <ol className="list-disc leading-tight space-y-[4vh] text-[1.5vw] mt-[10vh] text-justify font-[300] ml-[2vw] mr-[8vw]">
          <li>
            Software designed to reduce the operation cost of all buses for each
            trip for each route from each terminal while improving passenger
            comfort.
          </li>
          <li>
            System is flexible to work on various levels of ITS infrastructure
            (On board driver console, CCTV cameras within buses and bus stops
            and APC modules within buses).
          </li>
          <li>
            Software and training will be provided free of cost.
            Maintenance/hosting/subscription cost is insignificant compared to
            savings achieved in bus operations.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default ExtraInfo;
